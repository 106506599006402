/*

  File: index.js
  Kind: Page
  Description: Page banner with title and subtitle that are read from the site config

*/

import * as React from 'react';
import { graphql } from "gatsby"

//Components
import Layout from '../components/layout.js';
import { MDXRenderer } from 'gatsby-plugin-mdx';

//functions
import PrepareFragments from '../components/content/prepareFragments.js'

// markup
export default function IndexPage({data, location}) {

  const Fragments = PrepareFragments(data.fragments);

  return (
    <Layout pageTitle="home" location={location}>
      <MDXRenderer images={Fragments.intro.images}>{Fragments.intro.body}</MDXRenderer>
    </Layout>
  );
};

export const query = graphql`
  query {
    fragments: allMdx(filter: { fields: { collection: { eq: "fragment" }, locator: { page: { eq: "home" } } } }) {
      nodes {
        ...MarkdownFragments
      }
    }
  }
`;